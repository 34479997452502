import React, { useState, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function Login() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const { signIn } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const emailInput = useRef(null);
    const passwordInput = useRef(null);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');

    async function sendLogin(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setSuccessMessage("");

        if(email.trim() === ''){
            emailInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input_email);
            return;
        }else if(password.trim() === ''){
            passwordInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input_senha);
            return;
        }

        try{

            const response = await signIn(
                email,
                password,
            );
            
            setLoading(false);
            if (!response.user) {
                setError(true);
                setErrorMessage(language.error_incorrect);
                setSuccess(false);
                setSuccessMessage("");
                return;
            }

            if(query.get('redirect')==="" || query.get('redirect')===null || query.get('redirect')===undefined){
                navigate('/assistir');
            }else{
                navigate(query.get('redirect'));
            }
                

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_login);
            setSuccess(false);
            setSuccessMessage("");
        }
    }

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40 text-center">

                    <form onSubmit={sendLogin} className={`form ${ loading ? 'loading' : '' }`}>
                        <h3 className="title_b mb-60">
                            {language.login_title}
                        </h3>
                        <div className="d-input">
                            <label>{language.email}:</label>
                            <input type="email" ref={emailInput} value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="d-input">
                            <label>{language.just_password}:</label>
                            <input type="password" ref={passwordInput} value={password} onChange={e => setPassword(e.target.value)} />
                        </div>

                        {success && <div className="success space mt-20 mb-20">{success_message}</div>}
                        {error && <div className="error space mt-20 mb-20">{error_message}</div>}

                        <div className="d-btn mt-30">
                            <button type="submit" className="btn-send">{language.enter}</button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}
