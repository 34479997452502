import React, { createContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

const languages = {
    pt: {
        language: "pt",
        language_pt: "Português",
        language_en: "Inglês",
        language_sigla: "PT-BR",
        language_active: "Português",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        menu_home: "Home",
        menu_news: "Painelistas",
        menu_agenda: "Agenda",
        menu_inscricao: "Inscrição",
        menu_imprensa: "Imprensa",
        menu_contato: "Fale Conosco",
        menu_events: "Eventos",
        mais_info: "Mais informações e participação em breve.",
        reserve: "Reserve aqui",
        soon: "Em Breve.",
        agenda_btn: "AGENDA PRELIMINAR",
        desenvolvido: "Desenvolvido por",
        site_name: "G20 e a monotonia dos sistemas agroalimentares: a necessidade de uma nova era de domesticação de culturas e diversificação de dietas",
        name: "Nome",
        email: "E-mail",
        password: "Crie sua Senha",
        just_password: "Senha",
        organization: "Instituição / Organização",
        country: "País",
        age: "Idade",
        race: "Raça",
        genre: "Gênero",
        phone: "Contato WhatsApp",
        disability: "Deficiência / Necessidade Específica",
        food: "Restrição Alimentar",
        type: "Tipo de Participação",
        company: "Veículo de comunicação",
        presencial: "Presencial",
        online: "Online",
        select: "Selecione os Eventos que deseja participar",
        send: "ENVIAR",
        update: "ATUALIZAR",
        pre_inscricao: "PRÉ-INSCRIÇÃO",
        inscricao: "INSCRIÇÃO",
        error_input: "Preencha todos os campos obrigatórios!",
        error_type: "Preencha o Tipo de Participação!",
        success: "Informações enviadas com sucesso!",
        error_email: "E-mail já está sendo usado por outra conta!",
        enter: "ACESSAR",
        error_login: "Erro ao fazer login!",
        error_input_email: "Preencha o campo E-mail!",
        error_input_senha: "Preencha o campo Senha!",
        error_incorrect: "E-mail ou senha incorretos!",
        login_title: "Faça Login para acessar o Evento",
        select_watch: "Selecione o Evento que deseja assistir",
        error_select: "Selecione pelo menos um Evento!",
        error_found: "Código não encontrado!",
        code: "Código",
        hello: "Olá",
        logout: "Sair",
        back: "Voltar",
        not_comment: "Nenhum comentário encontrado.",
        write_comment: "Escreva seu comentário...",
        send_comment: "ENVIAR COMENTÁRIO",
        menu_watch: "Assista Aqui",
        click_here: "Clique Aqui",
        ended_online: "Inscrição apenas disponível para participação On-line",
        ended: "Inscrições presenciais encerradas",
        yes: "Sim",
        no: "Não",

        accept: "Aceito receber newsletters e divulgação de conteúdos e eventos das instituições organizadoras deste evento.",
        alert_register: "A inscrição inicial é uma pré-inscrição e não garante a vaga presencial. A confirmação estará sujeita à lotação e a confirmação da modalidade aprovada da sua inscrição será enviada por e-mail.",
    },
    en: {
        language: "en",
        language_pt: "Portuguese",
        language_en: "English",
        language_sigla: "EN",
        language_active: "English",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        menu_home: "Home",
        menu_news: "Panelists",
        menu_agenda: "Calendar",
        menu_inscricao: "Subscription",
        menu_imprensa: "Press",
        menu_contato: "Contact",
        menu_events: "Events",
        mais_info: "More info and participation soon.",
        reserve: "Make a reservation",
        soon: "Coming Soon.",
        agenda_btn: "PRELIMINARY AGENDA",
        desenvolvido: "Developed by",
        site_name: "G20 and the monotony of agri-food systems: The need for a new era of crop domestication & diets diversification",
        name: "Name",
        email: "Email",
        password: "Create your Password",
        just_password: "Password",
        organization: "Institution / Organization",
        country: "Country",
        age: "Age",
        race: "Race",
        genre: "Gender",
        phone: "WhatsApp Contact",
        disability: "Disability / Specific Need",
        food: "Food Restriction",
        type: "Type of participation",
        company: "Communication media",
        presencial: "In person",
        online: "Online",
        select: "Select the events you want to participate in",
        send: "SEND",
        update: "UPDATE",
        pre_inscricao: "PRE-REGISTRATION",
        inscricao: "ENROLLMENT",
        error_input: "Fill in all mandatory fields!",
        error_type: "Fill in the type of participation!",
        success: "Information sent successfully!",
        error_email: "Email is already being used by another account!",
        enter: "ACCESS",
        error_login: "Error when logging in!",
        error_input_email: "Fill in the email field!",
        error_input_senha: "Fill in the password field!",
        error_incorrect: "Incorrect email or password!",
        login_title: "Login to access the Event",
        select_watch: "Select the event you want to watch",
        error_select: "Select at least one Event!",
        error_found: "Code not found!",
        code: "Code",
        hello: "Hello",
        logout: "Logout",
        back: "Back",
        not_comment: "No comments found.",
        write_comment: "Write your comment ...",
        send_comment: "SEND COMMENT",
        menu_watch: "Watch Here",
        click_here: "Click Here",
        ended_online: "Registration only available for Online participation",
        ended: "In-person registrations closed",
        yes: "Yes",
        no: "No",
        accept: "I agree to receive newsletters and announcements from organizing institutions for this event.",
        alert_register: "The initial registration is a pre-registration and does not guarantee an in-person spot. Confirmation will be subject to capacity, and the approval of your registration mode will be sent by email.",
    }
}

export const LanguageProvider = ({ children }) => {
    
    const [language, setLanguage] = useState({});

    async function loadStorage() {
        const storageLanguage = localStorage.getItem('language');
        if (!storageLanguage) {
            setLanguageStore('pt');
        }else{
            setLanguageStore(storageLanguage);
        }
    }

    async function setStorage(lang) {
        localStorage.setItem('language', lang);
    }

    function setLanguageStore(lang){
        if(lang==="pt" || lang==="PT"){
            setLanguage(languages.pt);
            setStorage("pt");
        }else{
            setLanguage(languages.en);
            setStorage("en");
        }
    }

    useEffect(() => {
        loadStorage();
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, setLanguageStore }} >
            {children}
        </LanguageContext.Provider>
    )

}

export default LanguageContext;