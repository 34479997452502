import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';

import thumb from '../../assets/images/thumb.jpg';
import thumb_en from '../../assets/images/thumb_en.jpg';

export default function Watch() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const refVideo = useRef();


    return (
        <div className="page">
            <Header />
            <div className="content-video">
                <div className="full-video">
                    <div className="container-video">
                        <div ref={refVideo} className="embed-container">
                            <ReactPlayer
                                url={`https://www.youtube.com/embed/${lang==='pt' ? 'hrO8fJFo9BU' : 'OgvZLtoWo0I'}?rel=0&showinfo=0&autoplay=1`}
                                controls={true}
                                playsinline={true}
                                playing={true}
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
