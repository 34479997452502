import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';

import LanguageContext from '../../contexts/language';

export default function Home() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(false);
    const [home, setHome] = useState([]);

    async function loadHome(){
        setLoading(true);
        try{
            const response = await api.post(`/home`);
            setHome(response.data.content.home);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        loadHome();
    }, []);

    return (
        <div className="page">
            <Header />
            <div className="mt-40 mb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 m-auto">
                            {loading ?
                            <Loader />:
                            <div className="text" dangerouslySetInnerHTML={{__html: lang==='pt' ? home.description : home.description_en}} />}
                            <div className="d-btn mt-30 mobile-view">
                                <Link to="/inscricao" className="btn-send text-uppercase">{language.menu_inscricao}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


